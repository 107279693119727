<template>
    <div class="page">
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                        <h1>
                            {{ heading }}
                        </h1>

                        <div class="row justify-content-end mb-20">
                            <div class="col flex-grow-1" v-if="!createMode">
                                <p>Sist publisert: {{ sistOppdatert }}</p>
                            </div>

                            <div class="col flex-grow-0">
                                <button
                                    class="btn btn--primary btn--lg no-wrap"
                                    @click="onSaveClick"
                                >
                                    {{ publishText }}
                                </button>
                            </div>
                        </div>

                        <div class="section--edit bg--light">
                            <h2>Introduksjonsblokk</h2>

                            <FormGroup
                                v-bind="fields.tittel"
                                :value.sync="tema.tittel"
                                @on-blur="onFieldBlur"
                                @on-change="onChange"
                            />
                            <FormGroup
                                v-bind="fields.ingress"
                                :value.sync="tema.ingress"
                                :type="'textarea'"
                                @on-blur="onFieldBlur"
                                @on-change="onChange"
                            />
                            <div>
                                <label for="imageFileInput" class="form-label">
                                    Bilde *
                                </label>
                                <input
                                    class="form-control"
                                    type="file"
                                    id="imageFileInput"
                                    @change="onFileChange"
                                    ref="imageFileInput"
                                />
                                <p v-if="fileError" class="form-error">
                                    {{ fileError }}
                                </p>
                                <div v-if="newImage">
                                    <img :src="newImage" alt="" />
                                </div>
                                <div v-if="!newImage && tema.bilde">
                                    <ImageFile :id="tema.bilde" />
                                </div>

                                <button
                                    v-if="tema.bilde || newImage"
                                    class="btn btn--delete mt-20"
                                    @click.prevent="deleteImage"
                                >
                                    Slett bilde
                                </button>
                            </div>

                            <hr />

                            <h2 class="h3">Innholdsblokk</h2>
                            <div
                                class="input-group"
                                v-for="(block, index) in tema.innholdsblokker"
                                :key="'content-block_' + index"
                            >
                                <FormGroup
                                    :label="'Tittel på innholdsblokk'"
                                    :value.sync="block.tittel"
                                    :sortable="true"
                                    :maxlength="100"
                                    @on-move-up="
                                        onSortableUpClick(
                                            tema.innholdsblokker,
                                            index
                                        )
                                    "
                                    @on-move-down="
                                        onSortableDownClick(
                                            tema.innholdsblokker,
                                            index
                                        )
                                    "
                                />
                                <FormGroup
                                    :label="'Brødtekst'"
                                    :value.sync="block.brodtekst"
                                    :type="'textarea'"
                                />
                            </div>
                            <div>
                                <button
                                    class="btn btn--primary btn--add"
                                    @click.prevent="addInnholdsblokk"
                                >
                                    <span class="icon icon--add--white"></span>
                                    Legg til en ny innholdsblokk
                                </button>
                            </div>
                            <hr />
                            <h2 class="h3">Blokk for diskusjonspunkter</h2>
                            <div
                                class="input-group"
                                v-for="(bullet,
                                index) in tema.diskusjonspunkter"
                                :key="'bullet_' + index"
                            >
                                <FormGroup
                                    :label="'Diskusjonspunkt'"
                                    :value.sync="bullet.tekst"
                                    :sortable="true"
                                    :maxlength="256"
                                    @on-move-up="
                                        onSortableUpClick(
                                            tema.diskusjonspunkter,
                                            index
                                        )
                                    "
                                    @on-move-down="
                                        onSortableDownClick(
                                            tema.diskusjonspunkter,
                                            index
                                        )
                                    "
                                />
                            </div>
                            <div>
                                <button
                                    class="btn btn--primary btn--add"
                                    @click.prevent="addDiskusjonspunkt"
                                >
                                    <span class="icon icon--add--white"></span>
                                    Legg til et nytt diskusjonspunkt
                                </button>
                            </div>

                            <hr />

                            <h2 class="h3">
                                Blokk for verktøy og lenker
                            </h2>

                            <div
                                class="input-group"
                                v-for="(link, index) in tema.lenker"
                                :key="'link_' + index"
                            >
                                <FormGroup
                                    :label="'Lenketekst'"
                                    :value.sync="link.lenketekst"
                                    :sortable="true"
                                    :maxlength="100"
                                    @on-move-up="
                                        onSortableUpClick(tema.lenker, index)
                                    "
                                    @on-move-down="
                                        onSortableDownClick(tema.lenker, index)
                                    "
                                />
                                <FormGroup
                                    :label="'Lenkeadresse (URL)'"
                                    :value.sync="link.url"
                                    :maxlength="100"
                                />
                            </div>

                            <div>
                                <button
                                    class="btn btn--primary btn--add"
                                    @click.prevent="addLenke"
                                >
                                    <span class="icon icon--add--white"></span>
                                    Legg til en ny lenke
                                </button>
                            </div>
                        </div>
                        <div class="row justify-content-end">
                            <!-- <div class="col flex-grow-0" v-if="!createMode">
                                <button
                                    class="btn btn--delete btn--lg no-wrap"
                                    @click="onDeleteClick"
                                >
                                    Slett tema
                                </button>
                            </div> -->
                            <div class="col flex-grow-0">
                                <button
                                    class="btn btn--primary btn--lg no-wrap"
                                    @click="onSaveClick"
                                >
                                    {{ publishText }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ImageFile from '../../components/shared/ImageFile';
    import api from '../../scripts/api';
    import { mapActions } from 'vuex';
    import validationMixin from '../../mixins/validationMixin';
    import validate from '../../scripts/validate';

    export default {
        mixins: [validationMixin],
        components: {
            ImageFile
        },
        props: {
            id: String
        },
        data() {
            return {
                createMode: !this.id,
                imageFile: null,
                newImage: null,
                fileError: null,
                tema: {
                    tittel: '',
                    ingress: '',
                    innholdsblokker: [],
                    diskusjonspunkter: [],
                    lenker: [],
                    bilde: null
                },
                originalTema: '',
                fields: {
                    tittel: {
                        type: 'text',
                        name: 'tittel',
                        label: 'Tittel på HMS-tema *',
                        error: '',
                        maxlength: 100
                    },
                    ingress: {
                        type: 'text',
                        name: 'ingress',
                        label: 'Ingress *',
                        error: ''
                    }
                }
            };
        },
        computed: {
            heading() {
                return this.createMode
                    ? 'Opprett HMS-tema'
                    : 'Rediger HMS-tema';
            },
            publishText() {
                return this.createMode
                    ? 'Publiser HMS-tema'
                    : 'Publiser endringer';
            },
            sistOppdatert() {
                if (this.createMode) {
                    return null;
                }

                return new Date(this.tema.sistOppdatertUtc).toLocaleDateString(
                    'no'
                );
            }
        },
        methods: {
            ...mapActions(['updateCacheKey', 'setTemaCache']),
            addInnholdsblokk() {
                this.tema.innholdsblokker.push({
                    tittel: '',
                    brodtekst: '',
                    sortIndex: this.tema.innholdsblokker.length
                });
            },
            addDiskusjonspunkt() {
                this.tema.diskusjonspunkter.push({
                    tekst: '',
                    sortIndex: this.tema.diskusjonspunkter.length
                });
            },
            addLenke() {
                this.tema.lenker.push({
                    lenketekst: '',
                    url: '',
                    sortIndex: this.tema.lenker.length
                });
            },
            saveOriginal() {
                this.originalTema = JSON.stringify(this.tema);
            },
            setTema() {
                if (this.createMode) {
                    this.addInnholdsblokk();
                    this.addDiskusjonspunkt();
                    this.addLenke();
                    this.saveOriginal();
                } else {
                    api.getTema(this.id).then(res => {
                        this.tema = res;

                        if (!this.tema.innholdsblokker.length) {
                            this.addInnholdsblokk();
                        }
                        if (!this.tema.diskusjonspunkter.length) {
                            this.addDiskusjonspunkt();
                        }
                        if (!this.tema.lenker.length) {
                            this.addLenke();
                        }

                        this.saveOriginal();
                    });
                }
            },
            validateField(field) {
                let error = null;

                if (field.name === this.fields.tittel.name) {
                    if (!validate.stringLength(this.tema.tittel)) {
                        error = 'Tittel på HMS-tema må fylles inn';
                    }
                }

                if (field.name === this.fields.ingress.name) {
                    if (!validate.stringLength(this.tema.ingress)) {
                        error = 'Ingress må fylles inn';
                    }
                }

                field.error = error || '';
                field.validated = error ? true : false;

                return error;
            },
            getFilteredTema() {
                let tema = Object.assign({}, this.tema);

                tema.innholdsblokker = tema.innholdsblokker
                    .filter(
                        blokk => blokk.tittel.length || blokk.brodtekst.length
                    )
                    .map((innholdsblokk, index) => {
                        innholdsblokk.sortIndex = index;
                        return innholdsblokk;
                    });
                tema.diskusjonspunkter = tema.diskusjonspunkter
                    .filter(punkt => punkt.tekst.length)
                    .map((punkt, index) => {
                        punkt.sortIndex = index;
                        return punkt;
                    });
                tema.lenker = tema.lenker
                    .filter(
                        lenke => lenke.lenketekst.length && lenke.url.length
                    )
                    .map((lenke, index) => {
                        lenke.sortIndex = index;
                        return lenke;
                    });

                return tema;
            },
            deleteImage() {
                this.tema.bilde = null;
                this.imageFile = null;
                this.newImage = null;
                this.$refs.imageFileInput.value = null;
            },
            onFileChange(e) {
                this.fileError = null;
                var files = e.target.files || e.dataTransfer.files;

                if (!files.length) {
                    this.deleteImage();
                    return;
                }

                this.imageFile = files[0];
                this.setNewImage(this.imageFile);
            },
            setNewImage(file) {
                var reader = new FileReader();
                var vm = this;

                reader.onload = e => {
                    vm.newImage = e.target.result;
                };
                reader.readAsDataURL(file);
            },
            postFile(file) {
                let formData = new FormData();

                formData.append('file', file);
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                };

                return api.postFile(formData, config);
            },
            onSaveClick() {
                if (this.isFormValid()) {
                    const apiFn = this.createMode ? api.postTema : api.putTema;
                    let tema = this.getFilteredTema();

                    if (this.newImage) {
                        this.postFile(this.imageFile)
                            .then(res => {
                                tema.bilde = res.fil.id;
                                apiFn(tema).then(res => {
                                    let id = this.createMode ? res : this.id;
                                    this.handlePostSuccess(id);
                                });
                            })
                            .catch(() => {
                                this.fileError =
                                    'Noe gikk galt ved opplasting av bilde.';
                            });
                    } else {
                        apiFn(tema).then(res => {
                            let id = this.createMode ? res : this.id;
                            this.handlePostSuccess(id);
                        });
                    }
                }
            },
            handlePostSuccess(id) {
                this.updateCacheKey();
                this.saveOriginal();
                this.$router.push({
                    name: this.routes.tema,
                    params: {
                        id
                    }
                });
            },
            onSortableUpClick(list, currIndex) {
                if (currIndex === 0) {
                    return;
                }
                const newIndex = currIndex - 1;
                this.arraymove(list, currIndex, newIndex);
            },
            onSortableDownClick(list, currIndex) {
                if (currIndex === list.length) {
                    return;
                }
                const newIndex = currIndex + 1;
                this.arraymove(list, currIndex, newIndex);
            },
            arraymove(arr, fromIndex, toIndex) {
                var element = arr[fromIndex];
                arr.splice(fromIndex, 1);
                arr.splice(toIndex, 0, element);
            },
            onDeleteClick() {
                window.confirm(
                    `Du er i ferd med å slette denne aktiviteten. \nDette valget kan ikke angres. \n\nEr du sikker på at du vil slette "${this.aktivitet.tittel}"?`
                );
            }
        },
        beforeMount() {
            this.setTema();
        },
        beforeRouteLeave(to, from, next) {
            if (JSON.stringify(this.tema) !== this.originalTema) {
                const confirm = window.confirm(
                    `Du har gjort endringer som ikke er lagret. \nHvis du fortsetter vil du miste endringene dine. \n\nEr du sikker på at du vil fortsette?`
                );

                if (confirm) {
                    this.setTemaCache();
                    this.updateCacheKey();
                }

                next(confirm);
            } else {
                next();
            }
        }
    };
</script>
